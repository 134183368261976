import React, { useState } from "react";
import "./DriverNew.css";
import b2c from "./assets/Frame 576.png";
import b2b from "./assets/Group 145.png";
import valetparking from "./assets/Group 147.png";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CloseEye from "./assets/Vector.png";
import OpenEye from "./assets/Property 1=mdi_eye.png";
import profile from "./assets/Ellipse 13 (1).jpg"
import Driverprofilepics from "./assets/Group 147.jpg";

const DriverNew = () => {
  var driver = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768, // when screen width is 768px or less
        settings: {
          slidesToShow: 1, // show only one slide
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [showDropdown, setShowDropdown] = useState("");
  const [show1, setShow1] = useState("d-none");
  const [show2, setShow2] = useState("d-none");
  const [show3, setShow3] = useState("d-none");
  const [show4, setShow4] = useState("d-none");
  const [show5, setShow5] = useState("d-none");
  const [show6, setShow6] = useState("d-none");
  const [title1, setTitle1] = useState(CloseEye);
  const [title2, setTitle2] = useState(CloseEye);
  const [title3, setTitle3] = useState(CloseEye);
  const [title4, setTitle4] = useState(CloseEye);
  const [title5, setTitle5] = useState(CloseEye);
  const [title6, setTitle6] = useState(CloseEye);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);
  const [isVisible5, setIsVisible5] = useState(false);
  const [isVisible6, setIsVisible6] = useState(false);

  const toggle1 = () => {
    if (isVisible1) {
      setTitle1(CloseEye);
      setShow1("d-none");
    } else {
      setTitle1(OpenEye);
      setShow1("d-flex");
    }
    setIsVisible1(!isVisible1);
  };
  const toggle2 = () => {
    if (isVisible2) {
      setTitle2(CloseEye);
      setShow2("d-none");
    } else {
      setTitle2(OpenEye);
      setShow2("d-flex");
    }
    setIsVisible2(!isVisible2);
  };
  const toggle3 = () => {
    if (isVisible3) {
      setTitle3(CloseEye);
      setShow3("d-none");
    } else {
      setTitle3(OpenEye);
      setShow3("d-flex");
    }
    setIsVisible3(!isVisible3);
  };
  const toggle4 = () => {
    if (isVisible4) {
      setTitle4(CloseEye);
      setShow4("d-none");
    } else {
      setTitle4(OpenEye);
      setShow4("d-flex");
    }
    setIsVisible4(!isVisible4);
  };
  const toggle5 = () => {
    if (isVisible5) {
      setTitle5(CloseEye);
      setShow5("d-none");
    } else {
      setTitle5(OpenEye);
      setShow5("d-flex");
    }
    setIsVisible5(!isVisible5);
  };
  const toggle6 = () => {
    if (isVisible6) {
      setTitle6(CloseEye);
      setShow6("d-none");
    } else {
      setTitle6(OpenEye);
      setShow6("d-flex");
    }
    setIsVisible6(!isVisible6);
  };

  const countries = ["India", "USA", "Canada"];
  const states = {
    India: [
      "AndhraPradesh",
      "ArunachalPradesh",
      "Assam",
      "Bihar",
      "Chhattisgarh",
      "Goa",
      "Gujarat",
      "Haryana",
      "HimachalPradesh",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "MadhyaPradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "TamilNadu",
      "Telangana",
      "Tripura",
      "UttarPradesh",
      "Uttarakhand",
      "WestBengal",
    ],
    USA: ["California", "Texas", "New York"],
    Canada: ["Ontario", "Quebec", "British Columbia"],
  };

  const cities = {
    Maharashtra: ["Mumbai", "Pune", "Nagpur"],
    Karnataka: ["Bangalore", "Mysore", "Mangalore"],
    "Tamil Nadu": ["Chennai", "Coimbatore", "Madurai"],
    Gujarat: ["Ahmedabad", "Surat", "Vadodara"],
    AndhraPradesh: ["Amaravati", "Visakhapatnam", "Vijayawada"],
    ArunachalPradesh: ["Itanagar", "Pasighat", "Ziro"],
    Assam: ["Guwahati", "Dispur", "Silchar"],
    Bihar: ["Patna", "Gaya", "Muzaffarpur"],
    Chhattisgarh: ["Raipur", "Bilaspur", "Durg"],
    Goa: ["Panaji", "Margao", "Vasco da Gama"],
    Haryana: ["Chandigarh", "Faridabad", "Gurgaon"],
    HimachalPradesh: ["Shimla", "Dharamsala", "Solan"],
    Jharkhand: ["Ranchi", "Jamshedpur", "Dhanbad"],
    Kerala: ["Thiruvananthapuram", "Kochi", "Kozhikode"],
    MadhyaPradesh: ["Bhopal", "Indore", "Jabalpur"],
    Manipur: ["Imphal", "Thoubal", "Churachandpur"],
    Meghalaya: ["Shillong", "Tura", "Mawsynram"],
    Mizoram: ["Aizawl", "Lunglei", "Champhai"],
    Nagaland: ["Kohima", "Dimapur", "Mokokchung"],
    Odisha: ["Bhubaneswar", "Cuttack", "Rourkela"],
    Punjab: ["Chandigarh", "Ludhiana", "Amritsar"],
    Rajasthan: ["Jaipur", "Jodhpur", "Kota"],
    Sikkim: ["Gangtok", "Namchi", "Pakyong"],
    Telangana: ["Hyderabad", "Warangal", "Khammam"],
    Tripura: ["Agartala", "Udaipur", "Kailashahar"],
    UttarPradesh: ["Lucknow", "Kanpur", "Varanasi"],
    Uttarakhand: ["Dehradun", "Haridwar", "Nainital"],
    WestBengal: ["Kolkata", "Asansol", "Durgapur"],
    California: ["Los Angeles", "San Francisco", "San Diego"],
    Texas: ["Houston", "Dallas", "Austin"],
    "New York": ["New York City", "Buffalo", "Rochester"],
    Ontario: ["Toronto", "Ottawa", "Mississauga"],
    Quebec: ["Montreal", "Quebec City", "Laval"],
    "British Columbia": ["Vancouver", "Victoria", "Surrey"],
  };

  const areas = {
    Mumbai: ["Andheri", "Bandra", "Juhu"],
    Pune: ["Shivajinagar", "Kothrud", "Viman Nagar"],
    Bangalore: ["MG Road", "Indiranagar", "Whitefield"],
    Chennai: ["T. Nagar", "Velachery", "Anna Nagar"],
    Hyderabad: [
      "Erragadda",
      "Banjara Hills",
      "Jubilee Hills",
      "Kukatpally",
      "Madhapur",
      "Hitech City",
      "Gachibowli",
      "Kondapur",
      "Begumpet",
      "Secunderabad",
      "Ameerpet",
      "Nampally",
      "Charminar",
      "Old City",
    ],
    "Los Angeles": ["Hollywood", "Santa Monica", "Venice"],
    Toronto: ["Downtown", "Scarborough", "North York"],
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setSelectedState("");
    setSelectedCity("");
    setSelectedArea("");
    toggleDropdown("Country");
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setSelectedCity("");
    setSelectedArea("");
    toggleDropdown("State");
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
    setSelectedArea("");
    toggleDropdown("City");
  };
  const handleAreaChange = (e) => {
    setSelectedArea(e.target.value);
    toggleDropdown("Area");
  };
  const getArrowDirection = (dropdown) => {
    return showDropdown === dropdown ? "▲" : "▼";
  };

  const toggleDropdown = (dropdown) => {
    setShowDropdown(showDropdown === dropdown ? "" : dropdown);
  };

  return (
    <>
      <div className="DriverNewMain">
        <div className="DriverNewMain1">
          <h2>1.Car Drivers</h2>
          {/* b2c started */}
          <div className="b2c-main-container">
            <div className="b2c-content-container">
              <h3>
                a. B2C (Business to Customer) | Hire Temporary Halting Drivers{" "}
              </h3>
              <p>Explore Our Services & Book a Driver</p>
              <p>
                Rubia offers top-notch car driver services for all needs.
                Whether it’s a daily commute, an airport transfer, or a special
                occasion, we have the right driver for you.
              </p>
              <h4>How to Get Started?</h4>
              <div className="download-deatils">
                <h5>
                  1.Download the RUBIA App
                  <p>Available on the App Store and Google Play.</p>
                </h5>
              </div>
              <div className="download-deatils">
                <h5>
                  2.Sign Up & Book a Driver
                  <p>Available on the App Store and Google Play.</p>
                </h5>
              </div>
              <div className="download-deatils">
                <h5>
                  3.Driver Payment by the Hour
                  <li>Transparent hourly rates.</li>
                  <li>Pay only for the time you use.</li>
                </h5>
              </div>
            </div>
            <div className="b2c-image">
              <img src={b2c} alt="" />
            </div>
          </div>
          {/* b2c ended */}

          {/* downloadapp link started */}
          <div className="downloadapp-links">
            <h4>
              <span style={{ backgroundColor: "#650000", color: "white" }}>
                Download
              </span>{" "}
              the App Now!
            </h4>
            <h4>Experience the convenience and reliability of Ride.</h4>
            <div className="links-container">
              <div className="customerApp-container">
                <h3>Customer App</h3>
                <div className="link-buttons">
                  <button className="customerapp-link-buttons">
                    <a href="https://apps.apple.com/in/app/rubia/id6459829353">
                      Download iOS
                    </a>
                  </button>
                  <button className="customerapp-link-buttons">
                    <a href="https://play.google.com/store/apps/details?id=com.rubia.user&pcampaignid=web_share">
                      Download Android
                    </a>
                  </button>
                </div>
              </div>

              <div className="line"></div>
              <div className="DriverApp-container">
                <h3>Driver App</h3>
                <button className="Driverapp-link-buttons">
                  <a href="https://play.google.com/store/apps/details?id=com.rubia.driver&pcampaignid=web_share">
                    Download Android
                  </a>
                </button>
              </div>
            </div>
          </div>
          {/* end */}
          <hr style={{ border: "1px solid #650000" }} />
          {/* b2b started */}
          <div className="b2b-container">
            <div className="b2b-content">
              <h3>
                b. B2B(Business to Business) Driver
                <p>
                  <span style={{ color: "#C58643" }}>Introduction:</span>{" "}
                  Explain the services offered specifically for software
                  companies, servicing centers, and used vehicle dealerships.
                </p>
                <p>
                  <span style={{ color: "#650000" }}>
                    Detailed Services Sections:
                  </span>
                </p>
                <p>
                  <span style={{ color: "#C58643" }}>
                    For Software Companies:
                  </span>{" "}
                  Emphasize driver reliability and scheduling flexibility.
                </p>
                <p>
                  <span style={{ color: "#C58643" }}>
                    For Servicing Centers:
                  </span>{" "}
                  Highlight customer satisfaction and prompt service
                </p>
                <p>
                  <span style={{ color: "#C58643" }}>
                    For Used Vehicle Dealerships:
                  </span>{" "}
                  Focus on safe and timely vehicle transportation
                </p>
              </h3>
              <div className="b2b-button-container">
                <button className="b2b-button">Business entity login</button>
                <Link className="b2b-contact-btn" to="/contact/">
                  Contact us for more details
                </Link>
              </div>
            </div>
            <div className="b2b-image">
              <img src={b2b} alt="" />
            </div>
          </div>
          {/* b2b ended */}
          <hr style={{ border: "1px solid #650000" }} />
          {/* valet parking started */}
          <div className="valet-parking-conatainer">
            <div className="valet-parking-content">
              <h3>c. Valet Parking Car Drivers </h3>
              <li>Pubs</li>
              <li>Hospitals</li>
              <li>Showrooms</li>
              <li>Hotels</li>
              <li>Functions/Party</li>
              <li>Businesses</li>

              <div className="b2b-button-container">
                <Link className="valet-contact-btn" to="/contact/">
                  Contact us for more details
                </Link>
              </div>
            </div>
            <div className="valet-paking-image">
              <img src={valetparking} alt="" />
            </div>
          </div>
          {/* valet parking ended */}

          <hr style={{ border: "1px solid #650000" }} />
          {/* Permanent driver start */}
          <div className="hire-driver-container">
            <div className="hire-driver-header">
              <h3>d) Hire a Permanent / Monthly Car Drivers:</h3>
              <Link className="DriverRegister-btn">Register as Driver</Link>
            </div>
            <div className="dropdown-container">
              <select
                value={selectedCountry}
                onChange={handleCountryChange}
                className={`dropdown-select ${
                  selectedCountry === "Select   Country" &&
                  showDropdown === "Country"
                    ? "active"
                    : ""
                }`}
                id="dropdown-select-contry"
              >
                <option value="" disabled>
                  Select Country {getArrowDirection("Country")}
                </option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>

              <select
                value={selectedState}
                onChange={handleStateChange}
                className={`dropdown-select ${
                  selectedCountry === "Select   State" &&
                  showDropdown === "State"
                    ? "active"
                    : ""
                }`}
                disabled={!selectedCountry}
              >
                <option value="" disabled>
                  Select State {getArrowDirection("Country")}
                </option>
                {selectedCountry &&
                  states[selectedCountry].map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
              </select>

              <select
                value={selectedCity}
                onChange={handleCityChange}
                className={`dropdown-select ${
                  selectedCountry === "Select   City" && showDropdown === "City"
                    ? "active"
                    : ""
                }`}
                disabled={!selectedState}
              >
                <option value="" disabled>
                  Select City {getArrowDirection("Country")}
                </option>
                {selectedState &&
                  cities[selectedState].map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
              </select>

              <select
                value={selectedArea}
                onChange={(e) => setSelectedArea(e.target.value)}
                className={`dropdown-select ${
                  selectedCountry === "Select   Area" && showDropdown === "Area"
                    ? "active"
                    : ""
                }`}
                id="dropdown-select-area"
                disabled={!selectedCity}
              >
                <option value="" disabled>
                  Select Area {getArrowDirection("Country")}
                </option>
                {selectedCity &&
                  areas[selectedCity]?.map((area, index) => (
                    <option key={index} value={area}>
                      {area}
                    </option>
                  ))}
              </select>
            </div>
            <div className="Permanent-driverslider">
              <Slider {...driver}>
                <div className="driver-profile-container">
                  <div>
                    <div className="driver-image-name-location-container">
                      <div className="driver-cardprofile-img">
                        <img className="driverimg" src={profile} alt="" />
                      </div>
                      <div className="driver-name-location">
                        <p className="driver-name">Kaza Veera Sudhakara Rao</p>
                        <p className="driver-location">Erragadda</p>
                      </div>
                    </div>
                    <hr
                      style={{
                        border: "1px solid ",
                        width: "90%",
                        margin: "auto",
                      }}
                    />
                    <div className="driver-details-container">
                      <div className="deatils-headings">
                        <p>
                          Date of Barth{" "}
                          
                        </p>
                        <p>
                          Driving License
                         
                        </p>
                        <p>
                          No.of years Exp
                         
                        </p>
                        <p>
                          Transmission Typ{" "}
                         
                        </p>
                        <p>
                          Vehicle Type{" "}
                          
                        </p>
                        <p>
                          Number :
                        </p>
                      </div>

                      <div className="driver-details">
                        <p className="hiddde-content-container">
                          <p className={show1}>22/04/20022</p>
                          <img
                            src={title1}
                            alt="Toggle Visibility"
                            onClick={toggle1}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show2}>AP70720130001146</p>
                          <img
                            src={title2}
                            alt="Toggle Visibility"
                            onClick={toggle2}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show3}>5 Years</p>
                          <img
                            src={title3}
                            alt="Toggle Visibility"
                            onClick={toggle3}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show4}>Manual, A.M.T</p>
                          <img
                            src={title4}
                            alt="Toggle Visibility"
                            onClick={toggle4}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show5}>Hatchback,SUV</p>
                          <img
                            src={title5}
                            alt="Toggle Visibility"
                            onClick={toggle5}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show6}>Erragadda</p>
                          <img
                            src={title6}
                            alt="Toggle Visibility"
                            onClick={toggle6}
                            style={{ cursor: "pointer" }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="driver-profile-container">   <div>
                    <div className="driver-image-name-location-container">
                      <div className="driver-cardprofile-img">
                        <img className="driverimg" src={profile} alt="" />
                      </div>
                      <div className="driver-name-location">
                        <p className="driver-name">Kaza Veera Sudhakara Rao</p>
                        <p className="driver-location">Erragadda</p>
                      </div>
                    </div>
                    <hr
                      style={{
                        border: "1px solid ",
                        width: "90%",
                        margin: "auto",
                      }}
                    />
                    <div className="driver-details-container">
                      <div className="deatils-headings">
                        <p>
                          Date of Barth{" "}
                          
                        </p>
                        <p>
                          Driving License
                         
                        </p>
                        <p>
                          No.of years Exp
                         
                        </p>
                        <p>
                          Transmission Typ{" "}
                         
                        </p>
                        <p>
                          Vehicle Type{" "}
                          
                        </p>
                        <p>
                          Number :
                        </p>
                      </div>

                      <div className="driver-details">
                        <p className="hiddde-content-container">
                          <p className={show1}>22/04/20022</p>
                          <img
                            src={title1}
                            alt="Toggle Visibility"
                            onClick={toggle1}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show2}>AP70720130001146</p>
                          <img
                            src={title2}
                            alt="Toggle Visibility"
                            onClick={toggle2}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show3}>5 Years</p>
                          <img
                            src={title3}
                            alt="Toggle Visibility"
                            onClick={toggle3}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show4}>Manual, A.M.T</p>
                          <img
                            src={title4}
                            alt="Toggle Visibility"
                            onClick={toggle4}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show5}>Hatchback,SUV</p>
                          <img
                            src={title5}
                            alt="Toggle Visibility"
                            onClick={toggle5}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show6}>Erragadda</p>
                          <img
                            src={title6}
                            alt="Toggle Visibility"
                            onClick={toggle6}
                            style={{ cursor: "pointer" }}
                          />
                        </p>
                      </div>
                    </div>
                  </div></div>
                <div className="driver-profile-container">   <div>
                    <div className="driver-image-name-location-container">
                      <div className="driver-cardprofile-img">
                        <img className="driverimg" src={profile} alt="" />
                      </div>
                      <div className="driver-name-location">
                        <p className="driver-name">Kaza Veera Sudhakara Rao</p>
                        <p className="driver-location">Erragadda</p>
                      </div>
                    </div>
                    <hr
                      style={{
                        border: "1px solid ",
                        width: "90%",
                        margin: "auto",
                      }}
                    />
                    <div className="driver-details-container">
                      <div className="deatils-headings">
                        <p>
                          Date of Barth{" "}
                          
                        </p>
                        <p>
                          Driving License
                         
                        </p>
                        <p>
                          No.of years Exp
                         
                        </p>
                        <p>
                          Transmission Typ{" "}
                         
                        </p>
                        <p>
                          Vehicle Type{" "}
                          
                        </p>
                        <p>
                          Number :
                        </p>
                      </div>

                      <div className="driver-details">
                        <p className="hiddde-content-container">
                          <p className={show1}>22/04/20022</p>
                          <img
                            src={title1}
                            alt="Toggle Visibility"
                            onClick={toggle1}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show2}>AP70720130001146</p>
                          <img
                            src={title2}
                            alt="Toggle Visibility"
                            onClick={toggle2}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show3}>5 Years</p>
                          <img
                            src={title3}
                            alt="Toggle Visibility"
                            onClick={toggle3}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show4}>Manual, A.M.T</p>
                          <img
                            src={title4}
                            alt="Toggle Visibility"
                            onClick={toggle4}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show5}>Hatchback,SUV</p>
                          <img
                            src={title5}
                            alt="Toggle Visibility"
                            onClick={toggle5}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show6}>Erragadda</p>
                          <img
                            src={title6}
                            alt="Toggle Visibility"
                            onClick={toggle6}
                            style={{ cursor: "pointer" }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  </div>
                {/* Add more slides as needed */}
              </Slider>
            </div>
          </div>
          {/* Permanent driver ended */}
          <hr style={{ border: "1px solid #650000" }} />
          {/* Commercial Vehicle Divers started */}
          <div className="commecial-driver-container">
            <h3 className="commecial-driver-header">
              2.Commercial Vehicle Divers
            </h3>
            <div className="hire-driver-header">
              <h3>a) Bus drivers:</h3>
              <Link className="DriverRegister-btn">Register as Driver</Link>
            </div>
            <div className="dropdown-container">
              <select
                value={selectedCountry}
                onChange={handleCountryChange}
                className={`dropdown-select ${
                  selectedCountry === "Select   Country" &&
                  showDropdown === "Country"
                    ? "active"
                    : ""
                }`}
                id="dropdown-select-contry"
              >
                <option value="" disabled>
                  Select Country {getArrowDirection("Country")}
                </option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>

              <select
                value={selectedState}
                onChange={handleStateChange}
                className={`dropdown-select ${
                  selectedCountry === "Select   State" &&
                  showDropdown === "State"
                    ? "active"
                    : ""
                }`}
                disabled={!selectedCountry}
              >
                <option value="" disabled>
                  Select State {getArrowDirection("Country")}
                </option>
                {selectedCountry &&
                  states[selectedCountry].map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
              </select>

              <select
                value={selectedCity}
                onChange={handleCityChange}
                className={`dropdown-select ${
                  selectedCountry === "Select   City" && showDropdown === "City"
                    ? "active"
                    : ""
                }`}
                disabled={!selectedState}
              >
                <option value="" disabled>
                  Select City {getArrowDirection("Country")}
                </option>
                {selectedState &&
                  cities[selectedState].map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
              </select>

              <select
                value={selectedArea}
                onChange={(e) => setSelectedArea(e.target.value)}
                className={`dropdown-select ${
                  selectedCountry === "Select   Area" && showDropdown === "Area"
                    ? "active"
                    : ""
                }`}
                id="dropdown-select-area"
                disabled={!selectedCity}
              >
                <option value="" disabled>
                  Select Area {getArrowDirection("Country")}
                </option>
                {selectedCity &&
                  areas[selectedCity]?.map((area, index) => (
                    <option key={index} value={area}>
                      {area}
                    </option>
                  ))}
              </select>
            </div>
            <div>
            <Slider {...driver}>
                <div className="driver-profile-container">
                  <div>
                    <div className="driver-image-name-location-container">
                      <div className="driver-cardprofile-img">
                        <img className="driverimg" src={profile} alt="" />
                      </div>
                      <div className="driver-name-location">
                        <p className="driver-name">Kaza Veera Sudhakara Rao</p>
                        <p className="driver-location">Erragadda</p>
                      </div>
                    </div>
                    <hr
                      style={{
                        border: "1px solid ",
                        width: "90%",
                        margin: "auto",
                      }}
                    />
                    <div className="driver-details-container">
                      <div className="deatils-headings">
                        <p>
                          Date of Barth{" "}
                          
                        </p>
                        <p>
                          Driving License
                         
                        </p>
                        <p>
                          No.of years Exp
                         
                        </p>
                        <p>
                          Transmission Typ{" "}
                         
                        </p>
                        <p>
                          Vehicle Type{" "}
                          
                        </p>
                        <p>
                          Number :
                        </p>
                      </div>

                      <div className="driver-details">
                        <p className="hiddde-content-container">
                          <p className={show1}>22/04/20022</p>
                          <img
                            src={title1}
                            alt="Toggle Visibility"
                            onClick={toggle1}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show2}>AP70720130001146</p>
                          <img
                            src={title2}
                            alt="Toggle Visibility"
                            onClick={toggle2}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show3}>5 Years</p>
                          <img
                            src={title3}
                            alt="Toggle Visibility"
                            onClick={toggle3}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show4}>Manual, A.M.T</p>
                          <img
                            src={title4}
                            alt="Toggle Visibility"
                            onClick={toggle4}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show5}>Hatchback,SUV</p>
                          <img
                            src={title5}
                            alt="Toggle Visibility"
                            onClick={toggle5}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show6}>Erragadda</p>
                          <img
                            src={title6}
                            alt="Toggle Visibility"
                            onClick={toggle6}
                            style={{ cursor: "pointer" }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="driver-profile-container">   <div>
                    <div className="driver-image-name-location-container">
                      <div className="driver-cardprofile-img">
                        <img className="driverimg" src={profile} alt="" />
                      </div>
                      <div className="driver-name-location">
                        <p className="driver-name">Kaza Veera Sudhakara Rao</p>
                        <p className="driver-location">Erragadda</p>
                      </div>
                    </div>
                    <hr
                      style={{
                        border: "1px solid ",
                        width: "90%",
                        margin: "auto",
                      }}
                    />
                    <div className="driver-details-container">
                      <div className="deatils-headings">
                        <p>
                          Date of Barth{" "}
                          
                        </p>
                        <p>
                          Driving License
                         
                        </p>
                        <p>
                          No.of years Exp
                         
                        </p>
                        <p>
                          Transmission Typ{" "}
                         
                        </p>
                        <p>
                          Vehicle Type{" "}
                          
                        </p>
                        <p>
                          Number :
                        </p>
                      </div>

                      <div className="driver-details">
                        <p className="hiddde-content-container">
                          <p className={show1}>22/04/20022</p>
                          <img
                            src={title1}
                            alt="Toggle Visibility"
                            onClick={toggle1}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show2}>AP70720130001146</p>
                          <img
                            src={title2}
                            alt="Toggle Visibility"
                            onClick={toggle2}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show3}>5 Years</p>
                          <img
                            src={title3}
                            alt="Toggle Visibility"
                            onClick={toggle3}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show4}>Manual, A.M.T</p>
                          <img
                            src={title4}
                            alt="Toggle Visibility"
                            onClick={toggle4}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show5}>Hatchback,SUV</p>
                          <img
                            src={title5}
                            alt="Toggle Visibility"
                            onClick={toggle5}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show6}>Erragadda</p>
                          <img
                            src={title6}
                            alt="Toggle Visibility"
                            onClick={toggle6}
                            style={{ cursor: "pointer" }}
                          />
                        </p>
                      </div>
                    </div>
                  </div></div>
                <div className="driver-profile-container">   <div>
                    <div className="driver-image-name-location-container">
                      <div className="driver-cardprofile-img">
                        <img className="driverimg" src={profile} alt="" />
                      </div>
                      <div className="driver-name-location">
                        <p className="driver-name">Kaza Veera Sudhakara Rao</p>
                        <p className="driver-location">Erragadda</p>
                      </div>
                    </div>
                    <hr
                      style={{
                        border: "1px solid ",
                        width: "90%",
                        margin: "auto",
                      }}
                    />
                    <div className="driver-details-container">
                      <div className="deatils-headings">
                        <p>
                          Date of Barth{" "}
                          
                        </p>
                        <p>
                          Driving License
                         
                        </p>
                        <p>
                          No.of years Exp
                         
                        </p>
                        <p>
                          Transmission Typ{" "}
                         
                        </p>
                        <p>
                          Vehicle Type{" "}
                          
                        </p>
                        <p>
                          Number :
                        </p>
                      </div>

                      <div className="driver-details">
                        <p className="hiddde-content-container">
                          <p className={show1}>22/04/20022</p>
                          <img
                            src={title1}
                            alt="Toggle Visibility"
                            onClick={toggle1}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show2}>AP70720130001146</p>
                          <img
                            src={title2}
                            alt="Toggle Visibility"
                            onClick={toggle2}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show3}>5 Years</p>
                          <img
                            src={title3}
                            alt="Toggle Visibility"
                            onClick={toggle3}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show4}>Manual, A.M.T</p>
                          <img
                            src={title4}
                            alt="Toggle Visibility"
                            onClick={toggle4}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show5}>Hatchback,SUV</p>
                          <img
                            src={title5}
                            alt="Toggle Visibility"
                            onClick={toggle5}
                            style={{ cursor: "pointer" }}
                          />
                        </p>

                        <p className="hiddde-content-container">
                          <p className={show6}>Erragadda</p>
                          <img
                            src={title6}
                            alt="Toggle Visibility"
                            onClick={toggle6}
                            style={{ cursor: "pointer" }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  </div>
                {/* Add more slides as needed */}
              </Slider>
            </div>
          </div>
          {/* Commercial Vehicle Divers ended */}
        </div>
      </div>
    </>
  );
};

export default DriverNew;
